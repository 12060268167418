<template>
  <form>
    <div class="row">
      <div class="col-12 col-md-12 p-2">
        <label for="datepicker">{{
          t("lottoid", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="lottoId"
            v-model="editData.lottoId"
            class="form-control"
            :placeholder="t('lottoid', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("lottonumber", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="lottoNumber"
            v-model="editData.lottoNumber"
            class="form-control"
            :placeholder="t('lottonumber', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("setnumber", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="setNumber"
            v-model="editData.setNumber"
            class="form-control"
            :placeholder="t('setnumber', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("drawdateth", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="drawDateTh"
            v-model="editData.drawDateTh"
            class="form-control"
            :placeholder="t('drawdateth', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("drawdateen", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="drawDateEn"
            v-model="editData.drawDateEn"
            class="form-control"
            :placeholder="t('drawdateen', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("drawnumber", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="drawNumber"
            v-model="editData.drawNumber"
            class="form-control"
            :placeholder="t('drawnumber', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("lottorefnum", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="lottoRefNumber"
            v-model="editData.lottoRefNumber"
            class="form-control"
            :placeholder="t('lottorefnum', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("lottobarcode", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="lottoBarcodeRawData"
            v-model="editData.lottoBarcodeRawData"
            class="form-control"
            :placeholder="t('lottobarcode', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("lottobarcodetextapp", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="lottoBarcodeTextAppear"
            v-model="editData.lottoBarcodeTextAppear"
            class="form-control"
            :placeholder="t('lottovarcodetextapp', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("price", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="price"
            v-model="editData.price"
            class="form-control"
            :placeholder="t('price', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        {{ t("editlotto", {}, { locale: getLocal }) }}
      </div>
      <div class="col-12">
        <input
          type="file"
          id="image"
          @change="options.onFileChange"
          name="imageMulti"
          multiple
          accept="image/*"
        />
      </div>
      <div class="col-12 mt-2">
        <img class="w-100" :src="editData.IMAGESRC" alt="" />
      </div>
    </div>
    <div class="button-group d-flex pt-25">
      <button
        @click="options.sendEditData"
        class="btn btn-primary btn-default btn-squared text-capitalize"
      >
        {{ t("editlotto", {}, { locale: getLocal }) }}
      </button>
    </div>
  </form>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "FormEditListLottery",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data: function () {
    return {};
  },
  props: ["options", "editData"],
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style>
</style>