<template>
  <form>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{ t("Did", {}, { locale: getLocal }) }}</label>
        <div class="position-relative">
          <input
            type="text"
            name="drawId"
            v-model="editData.drawId"
            class="form-control"
            :placeholder="t('Did', {}, { locale: getLocal })"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 p-2">
        <label for="datepicker">{{
          t("Ddate", {}, { locale: getLocal })
        }}</label>
        <div class="position-relative">
          <input
            type="date"
            name="drawDate"
            v-model="editData.drawDate"
            class="form-control"
            :placeholder="t('Ddate', {}, { locale: getLocal })"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        {{ t("editdraw", {}, { locale: getLocal }) }}
      </div>
      <div class="col-12 p-2">
        <input
          type="file"
          id="background"
          @change="options.onFileChange"
          name="imageMulti"
          multiple
          accept="image/*"
        />
      </div>

      <div class="col-12 mt-2">
        <img class="w-100" :src="editData.IMAGESRC" alt="" />
      </div>
    </div>

    <div class="button-group d-flex pt-25">
      <button
        @click="options.sendEditData"
        class="btn btn-primary btn-default btn-squared text-capitalize"
      >
        {{ t("editdraw", {}, { locale: getLocal }) }}
      </button>
    </div>
  </form>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "FormEditDrawListLottery",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data: function () {
    return {};
  },
  props: ["options", "editData"],
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style>
</style>